<template>
  <div>
    <q-input
      dense
      outlined
      clearable
      v-model="search"
      :label="$t('Search')"
      :disable="isLoading"
      @clear="search = ''"
    />
    <template v-if="!isLoading">
      <div class="q-px-md q-mt-md q-mb-xs text-neutral-7">
        {{
          $t("settings.organization.productGroups.count", {
            count: filteredProductGroups.length,
          })
        }}
      </div>
      <q-separator />
    </template>
    <q-skeleton v-if="isLoading" />
    <q-list v-else separator>
      <q-item
        v-for="productGroup in filteredProductGroups"
        :key="productGroup.id"
        clickable
      >
        <q-item-section>
          <q-input
            type="text"
            v-model="productGroup.name"
            dense
            borderless
            @update:model-value="
              updateProductGroup(productGroup.id, { name: $event!.toString() })
            "
            debounce="300"
          />
        </q-item-section>
        <q-item-section side>
          <q-btn
            flat
            round
            size="sm"
            color="neutral-8"
            icon="sym_r_delete"
            @click.prevent.stop="deleteProductGroup(productGroup.id)"
          >
            <q-tooltip :delay="500">
              {{ $t("settings.organization.productGroups.remove") }}
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>
    </q-list>
    <div class="row justify-end q-my-md q-gutter-x-sm">
      <q-btn
        dense
        color="primary"
        icon="sym_r_add"
        :label="$t('settings.organization.productGroups.add')"
        @click="addProductGroup()"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import * as api from "@/api/productGroup";
import { useRouteParams } from "@/composables/useRouteParams";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import CreateProductGroupDialog from "./CreateProductGroupDialog.vue";
import type { ProductGroup } from "@/types/productGroup";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const q = useQuasar();

const { organizationId } = useRouteParams();

const isLoading = ref(false);

const productGroups = ref<ProductGroup[]>([]);
const search = ref("");
const filteredProductGroups = computed(() =>
  productGroups.value.filter((productGroup) =>
    productGroup.name.toLowerCase().includes(search.value.toLowerCase())
  )
);

onMounted(loadData);
async function loadData() {
  isLoading.value = true;

  try {
    productGroups.value = await api.listProductGroups(organizationId.value);
  } finally {
    isLoading.value = false;
  }
}

function addProductGroup() {
  q.dialog({
    component: CreateProductGroupDialog,
    componentProps: {
      organizationId: organizationId.value,
      groups: productGroups.value,
    },
  }).onOk(loadData);
}

async function updateProductGroup(
  productGroupId: number,
  productGroup: Partial<ProductGroup>
) {
  await api.updateProductGroup(
    organizationId.value,
    productGroupId,
    productGroup
  );
}

async function deleteProductGroup(productGroupId: number) {
  q.dialog({
    title: t("settings.organization.productGroups.remove"),
    message: t("settings.organization.productGroups.areYouSureToRemove"),
    ok: {
      color: "negative",
    },
    cancel: {
      flat: true,
      color: "neutral-10",
    },
    persistent: true,
  }).onOk(async () => {
    productGroups.value = productGroups.value.filter(
      (productGroup) => productGroup.id !== productGroupId
    );
    await api.deleteProductGroup(organizationId.value, productGroupId);
  });
}
</script>
