<template>
  <q-select
    v-model="checkCondition"
    :options="CHECK_CONDITION_OPTIONS"
    class="bg-neutral-1"
    emit-value
    map-options
    dense
    outlined
    hide-dropdown-icon
    hide-bottom-space
    hide-hint
    :option-label="translateCheckCondition"
    @update:model-value="updateCheckCondition"
  />
</template>
<script setup lang="ts">
import type {
  AutomationRule,
  CheckCondition,
  Condition,
} from "@/types/automationRule";
import { ref } from "vue";
import { CHECK_CONDITION_OPTIONS } from "@/types/automationRule";
import * as api from "@/api/automationRule";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  condition: Condition;
  automationRule: AutomationRule;
}>();

const checkCondition = ref<CheckCondition>(props.condition.checkCondition);

async function updateCheckCondition(newCheckCondition: CheckCondition) {
  checkCondition.value = newCheckCondition;
  await api.updateCondition(props.automationRule.id, props.condition.id, {
    checkCondition: newCheckCondition,
  });
}

const { t } = useI18n();

function translateCheckCondition(checkCondition: CheckCondition) {
  return t(`automationRule.checkCondition.${checkCondition}`);
}
</script>

<style scoped lang="scss"></style>
