<template>
  <q-select
    v-model="operator"
    :options="OPERATOR_OPTIONS"
    class="operator-input bg-neutral-1"
    emit-value
    map-options
    dense
    outlined
    hide-dropdown-icon
    hide-bottom-space
    hide-hint
    :option-label="translateOperator"
    @update:model-value="updateOperator"
  />
</template>
<script setup lang="ts">
import * as api from "@/api/automationRule";
import type {
  AutomationRule,
  Condition,
  Operator,
} from "@/types/automationRule";
import { OPERATOR_OPTIONS } from "@/types/automationRule";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  condition: Condition;
  automationRule: AutomationRule;
}>();

const operator = ref<Operator>(props.condition.leftOperator);

async function updateOperator(newOperator: Operator) {
  operator.value = newOperator;
  await api.updateCondition(props.automationRule.id, props.condition.id, {
    leftOperator: newOperator,
  });
}

const { t } = useI18n();

function translateOperator(operator: Operator) {
  return t(`automationRule.operator.${operator}`);
}
</script>

<style scoped lang="scss">
.operator-input {
  width: 60px;
  flex-shrink: 0;
}
</style>
