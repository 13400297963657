import type { Tag } from "@/types/tag";

export type AutomationRule = {
  id: number;
  name: string;
  description: string;
  tag: Tag | null;
  conditions: Condition[];
};

export type Condition = {
  id: number;
  parentId: number | null;
  checkCondition: CheckCondition;
  content: string;
  leftOperator: Operator;
  children: Condition[];
};

export type CheckCondition = "BOQ_TEXT_ICONTAINS" | "BOQ_SHORT_TEXT_ICONTAINS";
export type Operator = "AND" | "OR";

export const OPERATOR_OPTIONS: Operator[] = ["AND", "OR"];
export const CHECK_CONDITION_OPTIONS: CheckCondition[] = [
  "BOQ_TEXT_ICONTAINS",
  "BOQ_SHORT_TEXT_ICONTAINS",
];
