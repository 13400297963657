import { client } from "@/api/client/client";
import type { AutomationRule, Condition } from "@/types/automationRule";

export async function listInboxAutomationRules(
  organizationId: number,
  inboxId: number
) {
  const response = await client.get(`/inboxes/${inboxId}/automation_rules/`);
  return response.data;
}

export async function updateAutomationRule(
  inboxId: number,
  automationRuleId: number,
  automationRule: Partial<Omit<AutomationRule, "tag"> & { tagId: number }>
) {
  const response = await client.patch(
    `/inboxes/${inboxId}/automation_rules/${automationRuleId}/`,
    automationRule
  );
  return response.data;
}

export async function deleteAutomationRule(
  inboxId: number,
  automationRuleId: number
) {
  await client.delete(
    `/inboxes/${inboxId}/automation_rules/${automationRuleId}/`
  );
}

export async function createAutomationRule(inboxId: number) {
  const response = await client.post(`/inboxes/${inboxId}/automation_rules/`);
  return response.data;
}

export async function deleteCondition(
  automationRuleId: number,
  conditionId: number
) {
  await client.delete(
    `/automation_rules/${automationRuleId}/conditions/${conditionId}/`
  );
}

export async function createCondition(
  automationRuleId: number,
  parentId: number | null
) {
  const response = await client.post(
    `/automation_rules/${automationRuleId}/conditions/`,
    {
      parentId,
      leftOperator: "AND",
      checkCondition: "BOQ_TEXT_ICONTAINS",
      content: "",
    }
  );
  return { ...response.data, children: [] };
}
export async function updateCondition(
  automationRuleId: number,
  conditionId: number,
  condition: Partial<Condition>
) {
  const response = await client.patch(
    `/automation_rules/${automationRuleId}/conditions/${conditionId}/`,
    condition
  );
  return response.data;
}
